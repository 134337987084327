var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-submenu',{staticClass:"sidebar__nav-item submenu-documents",attrs:{"index":("" + _vm.isIndex),"disabled":_vm.documentItems.all.length === 0}},[_c('template',{slot:"title"},[_c('nav-link',{attrs:{"data":_vm.isData}})],1),_c('el-menu-item-group',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.isLoaded),expression:"!isLoaded"}]},[_c('div',{staticClass:"sidebar__nav-loading"},[_c('el-tree',{ref:"tree",staticClass:"submenu-documents__tree",attrs:{"props":_vm.treeProps,"data":_vm.treeDocArray,"load":_vm.treeDynamicLoadNode,"lazy":true,"draggable":_vm.isAdmin,"default-expanded-keys":_vm.treeKeySet,"auto-expand-parent":false,"allow-drop":_vm.TreeAllowDrop,"allow-drag":_vm.TreeAllowDrag,"node-key":"id"},on:{"node-drag-start":_vm.onTreeHandleDragStart,"node-drag-over":_vm.onTreeHandleDragOver,"node-drop":_vm.onTreeHandleDrop},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var node = ref.node;
var data = ref.data;
return _c('nav-link',{attrs:{"external":{
                        status: data.is_href,
                        target: data.is_href ? '_blank' : '_self'
                    },"data":Object.assign(
                        {
                            name:    data.title,
                            link:    data.link,
                            is_type: data.is_href ? 'material' : 'rubric',
                        },
                        data
                    )}})}}])})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }